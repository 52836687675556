/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    strong: "strong",
    h3: "h3",
    ol: "ol",
    li: "li",
    hr: "hr",
    h4: "h4",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Introdução:"), "\n", React.createElement(_components.p, null, "Em busca de oportunidades de emprego em Goiânia?"), "\n", React.createElement(_components.p, null, "Você está no lugar certo! A ", React.createElement(_components.a, {
    href: "https://dashboard.vendoor.me/",
    title: "Vendoor"
  }, "Vendoor"), ", uma plataforma de recrutamento líder, ", React.createElement(_components.strong, null, "está oferecendo quase 100 vagas imperdíveis na cidade"), ". Se você deseja encontrar um emprego que se alinhe às suas habilidades e aspirações, continue lendo para descobrir como a Vendoor pode ser a sua porta de entrada para uma carreira de sucesso."), "\n", React.createElement(_components.h3, null, "Explorando as Oportunidades em Goiânia:"), "\n", React.createElement(_components.p, null, "Goiânia, uma cidade vibrante e em constante crescimento, está repleta de oportunidades profissionais em diversos setores. Seja você um profissional experiente ou alguém em busca do primeiro emprego, encontrar a vaga certa pode ser desafiador. É aí que a ", React.createElement(_components.strong, null, "Vendoor"), " entra em cena, facilitando a conexão entre candidatos talentosos e empregadores em busca de talentos."), "\n", React.createElement(_components.h3, null, "A Plataforma Vendoor: Seu Caminho para o Sucesso Profissional:"), "\n", React.createElement(_components.p, null, "A Vendoor se destaca como uma plataforma de recrutamento rápido e eficiente, onde candidatos podem encontrar uma variedade de vagas em Goiânia. ", React.createElement(_components.strong, null, "Com quase 100 vagas atualmente disponíveis"), ", a plataforma oferece uma ampla gama de oportunidades, desde cargos iniciais até posições de liderança em diversas indústrias."), "\n", React.createElement(_components.h3, null, "Explorando as Vagas:"), "\n", React.createElement(_components.p, null, "Para começar sua jornada rumo a uma nova carreira, basta se cadastrar na ", React.createElement(_components.a, {
    href: "https://www.vendoor.me",
    title: "Vendoor"
  }, "Vendoor"), " e navegar até a seção de \"Vagas\". Lá, você encontrará uma lista abrangente de oportunidades disponíveis em Goiânia. Independentemente de sua área de interesse, há algo para todos."), "\n", React.createElement(_components.h3, null, "Processo Seletivo Rápido e Eficiente:"), "\n", React.createElement(_components.p, null, "Uma das maiores vantagens de usar a Vendoor é a rapidez e eficiência do processo seletivo. Ao se candidatar a uma vaga, você entrará em um processo que valoriza seu tempo e esforço. Empregadores parceiros da Vendoor reconhecem a importância de conduzir processos seletivos ágeis, garantindo que candidatos qualificados não percam oportunidades valiosas."), "\n", React.createElement(_components.h2, null, React.createElement(_components.strong, null, "Passos Simples para Começar:")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Cadastro:"), " Primeiramente, registre-se na plataforma Vendoor. Preencha suas informações de maneira completa e precisa para que empregadores possam conhecer suas habilidades e experiências."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Explorando Vagas:"), " Navegue pela seção \"Vagas\" para encontrar posições que correspondam ao seu perfil e objetivos profissionais. Utilize filtros para refinar sua busca, tornando o processo ainda mais direcionado. Você também pode navegar por todas as vagas acessando o ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/"
  }, "portal de vagas da Vendoor"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Candidatura:"), " Ao encontrar uma vaga que desperte seu interesse, clique para saber mais detalhes. Se a vaga for a escolha certa para você, envie sua candidatura com apenas alguns cliques."), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "Conclusão:"), "\n", React.createElement(_components.p, null, "Se você está ansioso para embarcar em uma nova jornada profissional em Goiânia, a ", React.createElement(_components.a, {
    href: "https://dashboard.vendoor.me",
    title: "Vendoor"
  }, "Vendoor"), " é a sua aliada ideal. Com uma ampla gama de vagas disponíveis e um processo seletivo eficiente, você pode dar o primeiro passo em direção ao emprego dos seus sonhos. Cadastre-se na Vendoor, explore as vagas disponíveis e abra as portas para um futuro promissor em sua carreira."), "\n", React.createElement(_components.p, null, "Lembre-se, a ", React.createElement(_components.a, {
    href: "https://www.vendoor.com.br/"
  }, "Vendoor"), " está aqui para conectar você com oportunidades que combinem com suas habilidades e ambições. Aproveite essa chance e inicie sua jornada rumo ao sucesso profissional!"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h4, null, "Créditos"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Autor do artigo:"), " Gustavo de paula\n", React.createElement("br"), React.createElement(_components.em, null, "Revisado"), " por Lisanna Reis"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
